import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { requiresAuth } from '../../config/routes';

const Checkout = lazy(() => import('./pages/Checkout'));
const CheckoutSuccess = lazy(() => import('./pages/CheckoutSuccess'));

export const checkoutRoutes = {
  checkoutSuccess: '/checkout/success',
  checkout: (status) => (status ? `/checkout/${status}` : '/checkout/:status'),
};

export default function CheckoutRoutes() {
  return (
    <Switch>
      <Route
        exact
        component={requiresAuth(() => (
          <CheckoutSuccess />
        ))}
        path={checkoutRoutes.checkoutSuccess}
      />
      <Route
        exact
        component={requiresAuth(() => (
          <Checkout />
        ))}
        path={checkoutRoutes.checkout()}
      />
    </Switch>
  );
}
