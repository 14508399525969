import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { requiresAuth } from '../../config/routes';

const CellarWines = lazy(() => import('./pages/CellarWines'));
const WineDetails = lazy(() => import('./pages/WineDetails'));

export const cellarRoutes = {
  wines: '/cellar/wines',
  wineDetail: (wineId, size, status) =>
    wineId && size && status
      ? `/cellar/wines/${wineId}/${size}/${status}`
      : '/cellar/wines/:id/:size/:status',
};

export default function CellarRoutes() {
  return (
    <Switch>
      <Route
        exact
        component={requiresAuth(() => (
          <CellarWines />
        ))}
        path={cellarRoutes.wines}
      />
      <Route
        exact
        component={requiresAuth(() => (
          <WineDetails />
        ))}
        path={cellarRoutes.wineDetail()}
      />
    </Switch>
  );
}
