import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { requiresAuth } from '../../config/routes';

const CartOverview = lazy(() => import('./pages/CartOverview'));
const Cart = lazy(() => import('./pages/Cart'));

export const cartRoutes = {
  cartOverview: '/cart/overview',
  cart: '/cart',
};

export default function CartRoutes() {
  return (
    <Switch>
      <Route
        exact
        component={requiresAuth(() => (
          <CartOverview />
        ))}
        path={cartRoutes.cartOverview}
      />
      <Route
        exact
        component={requiresAuth(() => (
          <Cart />
        ))}
        path={cartRoutes.cart}
      />
    </Switch>
  );
}
